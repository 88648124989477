var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staking-dao-instance-table-container"},[_c('CCard',{staticClass:"staking-dao-instance-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"contract_address",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.contract_address))])]}},{key:"total_staked_amount_big",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.total_staked_amount_big)+" ETH")])]}},{key:"remaining_amount_big",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.remaining_amount_big)+" ETH")])]}},{key:"progress",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.progress)+"%")])]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.status))])]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('button',{staticClass:"btn",on:{"click":function($event){return _vm.viewDetails(item)}}},[_vm._v("Details")])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }