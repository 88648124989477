<template>
  <div class="search-staking-instance-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{ $t('SEARCH_STAKING_DAO_DETAILS_HEADER_COMPONENT_STATUS_LABEL') }}</label>
            <select v-model="searchData.status" class="form-control">
              <option value>{{ $t('SEARCH_STAKING_DAO_DETAILS_HEADER_COMPONENT_ALL_STATUS_OPTION') }}</option>
              <option v-for="item in statusList" :key="item.value" :value="item.value">{{ $t(item.label) }}</option>
            </select>
          </div>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <CCol md="3" sm="12"> </CCol>
      <CCol md="3" sm="12"> </CCol>
      <CCol md="3" sm="12">
        <CButton class="btn btn-reset" @click="resetForm()">{{ $t('SEARCH_USER_ASSET_HEADER_COMPONENT_RESET_BUTTON') }}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton class="btn btn-search" @click="search()">{{ $t('SEARCH_USER_ASSET_HEADER_COMPONENT_SEARCH_BUTTON') }}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import sessionStorageHelper from '@/utils/sessionStorage-helper';

export default {
  name: 'SearchStakingDaoInstanceHeader',
  data() {
    return {
      statusList: [
        {
          value: 'DEPOSITING',
          label: 'STAKING_DAO_DETAILS_STATUS_DEPOSITING',
        },
        {
          value: 'INITIALIZING',
          label: 'STAKING_DAO_DETAILS_STATUS_INITIALIZING',
        },
        {
          value: 'ACTIVATING',
          label: 'STAKING_DAO_DETAILS_STATUS_ACTIVATING',
        },
        {
          value: 'ACTIVE',
          label: 'STAKING_DAO_DETAILS_STATUS_ACTIVE',
        },
        {
          value: 'INACTIVE',
          label: 'STAKING_DAO_DETAILS_STATUS_INACTIVE',
        },
        {
          value: 'DISMANTLING',
          label: 'STAKING_DAO_DETAILS_STATUS_DISMANTLING',
        },
        {
          value: 'DISMANTLED',
          label: 'STAKING_DAO_DETAILS_STATUS_DISMANTLED',
        },
        {
          value: 'RUNNING',
          label: 'STAKING_DAO_DETAILS_STATUS_RUNNING',
        },
      ],
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterStakingDao) || this.getDefaultValues(),
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    platformList: {
      type: Array,
      default: () => [],
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        status: '',
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },
    async search() {
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterStakingDao, this.searchData);

      const searchData = {
        ...this.searchData,
      };
      this.onSearch(searchData);
    },
  },
};
</script>

<style lang="scss">
.search-staking-instance-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
