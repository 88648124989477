<template>
  <div class="staking-dao-instance-table-container">
    <CCard class="staking-dao-instance-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #contract_address="{ item }">
          <td>{{ item.contract_address }}</td>
        </template>
        <template #total_staked_amount_big="{ item }">
          <td>{{ item.total_staked_amount_big }}&nbsp;ETH</td>
        </template>
        <template #remaining_amount_big="{ item }">
          <td>{{ item.remaining_amount_big }}&nbsp;ETH</td>
        </template>
        <template #progress="{ item }">
          <td>{{ item.progress }}%</td>
        </template>
        <template #status="{ item }">
          <td>{{ item.status }}</td>
        </template>
        <template #action="{ item }">
          <td>
            <button class="btn" @click="viewDetails(item)">Details</button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { Constants } from '@/constants';

export default {
  name: 'StakingDaoInstanceTable',
  components: {},
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'contract_address',
          label: this.$t('STAKING_DAO_TABLE_COL_CONTRACT'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'total_staked_amount_big',
          label: this.$t('STAKING_DAO_TABLE_COL_TOTAL_STAKED'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'remaining_amount_big',
          label: this.$t('STAKING_DAO_TABLE_COL_REMAINNING'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'progress',
          label: this.$t('STAKING_DAO_TABLE_COL_PROGRESS'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'status',
          label: this.$t('STAKING_DAO_TABLE_COL_STATUS'),
          _style: 'min-width: 80px; text-align: left;',
        },
        {
          key: 'action',
          label: this.$t('STAKING_DAO_TABLE_ACTION'),
          _style: 'min-width: 80px; text-align: left;',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    viewDetails(item) {
      this.$router.push(`staking-dao/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.staking-dao-instance-table-container {
  .staking-dao-instance-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
